<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Soubory - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">

        <a-form-item
          :required="true"
        >
          <a-upload-dragger
            :fileList="this.item"
            name="file"
            :multiple="true"
            :beforeUpload="beforeUpload"
            accept="audio/*,video/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,.pdf,.plain,.rtf,vnd.ms-excel,vnd.ms-powerpoint,vnd.oasis.opendocument.spreadsheet,vnd.oasis.opendocument.text,vnd.openxmlformats-officedocument.presentationml.presentation,vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            listType="picture"
            :supportServerRender="true"
            :showUploadList="false"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">Chcete-li nahrát soubor, klikněte nebo přetáhněte soubor do této oblasti</p>
          </a-upload-dragger>
          <div class="ant-upload-list ant-upload-list-picture my-upload-list" v-if="item.length > 0">
            <a-row type="flex" justify="start" class="mt-5 mb-3 my-upload-list-actions">
              <a-col :span="4">
                <a-select
                  @change="handleChangeAllCategory"
                  showSearch
                  placeholder="Hromadně nastavit kategorii"
                  :options="categories.map(x => { return { value: x.fcy_id, label: x.fcy_name }})"
                  optionFilterProp="children"
                  :filterOption="filterOption"
                  :loading="!loaded.includes('fcy')"
                />
              </a-col>
            </a-row>
            <div :key="index" v-for="(item, index) in this.item" class="ant-upload-list-item">
              <div class="ant-upload-list-item-info">
                <span>
                  <a-row>
                    <a-col :span="8">
                      <a-icon type="file" class="ant-upload-list-item-thumbnail"></a-icon>
                      <span :title="item.name" class="ant-upload-list-item-name">{{ item.name }}</span>
                    </a-col>
                    <a-col :span="16">
                      <a-form-item
                        label=""
                        :validate-status="error('fcy_id') ? 'error' : ''"
                        :help="error('fcy_id') || ''"
                      >
                        <a-select
                          style="max-width: 200px;"
                          v-decorator="[`fcy_id[${index}]`, {rules: [{required: true, message: 'Kategorie musí být vyplněna!'}]}]"
                          showSearch
                          placeholder="-- Vyber kategorii --"
                          :options="categories.map(x => { return { value: x.fcy_id, label: x.fcy_name }})"
                          optionFilterProp="children"
                          :filterOption="filterOption"
                          :loading="!loaded.includes('fcy')"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </span>
              </div>
              <a-icon class="anticon anticon-close" type="close" @click="handleRemove(index)" />
            </div>
          </div>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="item.length === 0 || loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'

const mimeTypes = [
  'csv',
  'msword',
  'pdf',
  'plain',
  'rtf',
  'vnd.ms-access',
  'vnd.ms-excel',
  'vnd.ms-powerpoint',
  'vnd.oasis.opendocument.spreadsheet',
  'vnd.oasis.opendocument.text',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export default {
  components: {
    ActionTools,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/file',
          title: 'Seznam',
        },
      ],
      item: [],
      loaded: [],
    }
  },
  computed: {
    categories: function () {
      return this.$store.getters['fileCategory/filteredItems']('')
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
    },
    handleChangeAllCategory(id) {
      this.form.getFieldValue('fcy_id').forEach((value, key) => {
        this.form.setFieldsValue({
          ['fcy_id[' + key + ']']: id,
        })
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('file/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      const data = new FormData()
      this.item.forEach((value, key) => {
        data.append('files[' + key + ']', value)
      })
      data.append('json', JSON.stringify({ file_category: this.form.getFieldValue('fcy_id').map(x => { return { fcy_id: x } }) }))
      return data
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    beforeUpload(file) {
      if (mimeTypes.includes(file.type.slice(file.type.lastIndexOf('/') + 1))) {
        const newIndex = this.item.length
        this.item.push(file)
        this.form.getFieldDecorator('fcy_id[' + newIndex + ']')
        this.form.setFields({
          ['fcy_id[' + newIndex + ']']: { value: undefined, errors: ['Kategorie pozice musí být vyplněna!'] },
        })
      }
      return false
    },
    handleRemove(index) {
      this.item.splice(index, 1)
    },
    initData() {
      this.loading = true
      this.$store.dispatch('fileCategory/getList')
        .catch(() => {})
        .finally(() => {
          this.loaded.push('fcy')
          this.loading = false
        })
    },
  },
  created() {
    this.initData()
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
